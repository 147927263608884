import { useContext, useState } from 'react';
import { TimelinePageContext } from '..';
import styles from './RecommendTabSection.module.scss';
import { PostsLoader } from 'components/common/PreloadContentLoader';
import PostComponent from 'components/Post';
import CreatePostBox from 'components/CreatePostBox';
import { useMediaQuery } from 'common/utils';
// import LiveUsers from '../LiveUsers';
import { PostParentKey } from 'common/interfaces/api';
import HomeRecommendCreatorsList from '../HomeRecommendCreatorsList';
import SubscribingSection from '../SubscribingSection';
import { SymbolTabsName } from 'common/utils/chart';
import useStore from 'store/timeline';
import { ScreenName } from 'common/utils/pp_tracking';
import dynamic from 'next/dynamic';
const SymbolDataView = dynamic(
  () => import('components/ChartSection/SymbolDataView'),
  {
    ssr: false,
  }
);

const RecommendTabSection = () => {
  const isMobile = useMediaQuery('(max-width: 767px)');
  const currentUser = useStore((state) => state.currentUser);
  const [voteTabId, setVoteTabId] = useState(SymbolTabsName.VoteResult);
  const { isFetchedTimeline, trendingPosts, symbolId } =
    useContext(TimelinePageContext);
  return (
    <div className={styles.recommendTabSection}>
      {!isMobile && (
        <div className={styles.createPost}>
          <CreatePostBox />
        </div>
      )}
      {currentUser && symbolId && (
        <SymbolDataView
          screenName={ScreenName.HomeTimeline}
          symbolId={symbolId}
          parentTabId={voteTabId}
          setParentTabId={setVoteTabId}
        />
      )}
      {/* <LiveUsers /> */}
      <div className={styles.postsList}>
        {isFetchedTimeline ? (
          (trendingPosts || []).map((post, index) => (
            <PostComponent
              post={post}
              parent={PostParentKey.HomeRecommendPosts}
              hasFollowBtn
              key={`${post?.id}-${index}`}
            />
          ))
        ) : (
          <PostsLoader />
        )}
      </div>
      <HomeRecommendCreatorsList />
      <SubscribingSection />
    </div>
  );
};

export default RecommendTabSection;
