/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import axios from 'axios';
import useGlobalStore from 'store/global';
import useStore from 'store/timeline';
import { useInfiniteQuery } from 'react-query';
import { useEffect, useMemo, useState } from 'react';
import { AnimatePresence } from 'framer-motion';

import PostItem from 'components/Post';
import InfiniteScroll from 'components/common/InfiniteScroll';
import { Post, PostParentKey } from 'common/interfaces/api';
import styles from './SubscribingSection.module.scss';
import { useMountedDelay } from 'common/utils/hooks/useDidMount';

const SubscribingSection = (): JSX.Element => {
  const shouldRefetchPosts = useStore((state) => state.shouldRefetchPosts);
  const isAuthInit = useGlobalStore((state) => state.isAuthInit);
  const currentAuth = useGlobalStore((state) => state.currentAuth);
  const mounted = useMountedDelay();
  const { isLoading, data, fetchNextPage, refetch, isFetchingNextPage } =
    useInfiniteQuery(
      'getSubscribingPosts',
      async ({ pageParam = { page: 3, lastPostId: '' } }) => {
        if (!isAuthInit || !mounted) return Promise.reject();
        const paramsStr = `?page=${String(
          pageParam?.page
        )}&last_post_id=${String(pageParam?.lastPostId)}`;
        return (await axios.get(`/feeds/home-timeline${paramsStr}`))?.data?.data
          ?.sections[0] as { latest_posts: Post[] };
      },
      {
        getNextPageParam: (lastPage, pages) => {
          const posts = lastPage?.latest_posts;
          if (posts && posts.length > 0) {
            return {
              page: pages.length + 3,
              lastPostId: posts[posts.length - 1]?.id,
            };
          }
        },
        enabled: isAuthInit && !!currentAuth && mounted,
        refetchOnWindowFocus: false,
        staleTime: 15 * 60 * 1000,
        cacheTime: 15 * 60 * 1000,
      }
    );

  useEffect(() => {
    if (shouldRefetchPosts) {
      void refetch();
    }
  }, [shouldRefetchPosts, refetch]);

  const pages = data?.pages;
  const posts: Post[] = useMemo(
    () =>
      pages
        ? pages.reduce<Post[]>((curr, page) => {
            const posts = page?.latest_posts || [];
            return curr.concat(posts);
          }, [])
        : [],
    [pages]
  );

  // Animation
  const [doUseAnimation, setUseAnimation] = useState(false);

  // Prevent animation from playing on first load
  useEffect(() => {
    let timeout;
    if (!isLoading) {
      timeout = setTimeout(() => setUseAnimation(true));
    }
    return () => {
      clearTimeout(timeout);
      setUseAnimation(false);
    };
  }, [isLoading]);

  if (!currentAuth) return <></>;
  return (
    <div className={styles.sectionWrapper}>
      <InfiniteScroll
        fetchNextPage={fetchNextPage}
        isLoading={isFetchingNextPage}
      >
        <AnimatePresence initial={false}>
          {posts.map((post, index) => (
            <PostItem
              post={post}
              key={`${post?.id}-${index}`}
              hasFollowBtn
              shouldAnimate={doUseAnimation}
              parent={PostParentKey.HomeRecommendPosts}
            />
          ))}
        </AnimatePresence>
      </InfiniteScroll>
    </div>
  );
};

export default SubscribingSection;
